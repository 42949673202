import React from "react"
import Layout from '../components/layout'
import { Card, Col, Row, Container } from "react-bootstrap"
import { StaticImage } from "gatsby-plugin-image"

const CartScreen = () => {
    return (
        <Layout>
            <Container>
                <Row className="mb-3"><h3>Brands</h3></Row>
                <Row>
                    <Col md={3} className="mb-3">
                        <Card>
                            <a href="https://www.airhead.com/" target="_blank" className="text-center">
                                <StaticImage height={150} loading="eager" src="../images/ticker/Airhead.png" />
                            </a>

                        </Card>
                    </Col>
                    <Col md={3} className="mb-3">
                        <Card>
                            <a href="https://www.brigadewakesurfing.com/" target="_blank" className="text-center">
                                <StaticImage height={150} loading="eager" src="../images/ticker/Brigade.png" />
                            </a>
                        </Card>
                    </Col>
                    <Col md={3} className="mb-3">
                        <Card>
                            <a href="https://damnbugz.com/" target="_blank" className="text-center">
                                <StaticImage height={150} loading="eager" src="../images/ticker/DamnBugz.png" />
                            </a>
                        </Card>
                    </Col>
                    <Col md={3} className="mb-3">
                        <Card>
                            <a href="https://www.fatsac.com/" target="_blank" className="text-center">
                                <StaticImage height={150} loading="eager" src="../images/ticker/fatsac.png" />
                            </a>
                        </Card>
                    </Col>
                    {/*<Col md={3} className="mb-3">
                        <Card>
                            <a href="https://glidesoul.com/" target="_blank" className="text-center">
                                <StaticImage height={150} loading="eager" src="../images/ticker/GlideSoul.png" />
                            </a>
                        </Card>
                    </Col>*/}
                    <Col md={3} className="mb-3">
                        <Card>
                            <a href="https://www.jobesports.com/en/" target="_blank" className="text-center">
                                <StaticImage height={150} loading="eager" src="../images/ticker/Jobe.png" />
                            </a>
                        </Card>
                    </Col>
                    <Col md={3} className="mb-3">
                        <Card>
                            <a href="https://kanukboardco.com/" target="_blank" className="text-center">
                                <StaticImage height={150} loading="eager" src="../images/ticker/Kanuk.png" />
                            </a>
                        </Card>
                    </Col>
                    <Col md={3} className="mb-3">
                        <Card>
                            <a href="https://apparel.mastercraft.com/" target="_blank" className="text-center">
                                <StaticImage height={150} loading="eager" src="../images/ticker/MasterCraft.png" />
                            </a>
                        </Card>
                    </Col>
                    <Col md={3} className="mb-3">
                        <Card>
                            <a href="https://www.mysticboarding.com/" target="_blank" className="text-center">
                                <StaticImage height={150} loading="eager" src="../images/ticker/Mystic.png" />
                            </a>
                        </Card>
                    </Col>
                    <Col md={3} className="mb-3">
                        <Card>
                            <a href="https://nauticurl.com/" target="_blank" className="text-center">
                                <StaticImage height={150} loading="eager" src="../images/ticker/Nauticurl.png" />
                            </a>
                        </Card>
                    </Col>
                    <Col md={3} className="mb-3">
                        <Card>
                            <a href="https://rack-a-nize.square.site/" target="_blank" className="text-center">
                                <StaticImage height={150} loading="eager" src="../images/ticker/Rackanize.png" />
                            </a>
                        </Card>
                    </Col>
                    <Col md={3} className="mb-3">
                        <Card>
                            <a href="https://radarskis.com/" target="_blank" className="text-center">
                                <StaticImage height={150} loading="eager" src="../images/ticker/Radar.png" />
                            </a>
                        </Card>
                    </Col>
                    <Col md={3} className="mb-3">
                        <Card>
                            <a href="https://ronixwake.com/" target="_blank" className="text-center">
                                <StaticImage height={150} loading="eager" src="../images/ticker/Ronix.png" />
                            </a>
                        </Card>
                    </Col>
                    <Col md={3} className="mb-3">
                        <Card>
                            <a href="https://www.rvca.com/" target="_blank" className="text-center">
                                <StaticImage height={150} loading="eager" src="../images/ticker/RVCA.png" />
                            </a>
                        </Card>
                    </Col>
                    <Col md={3} className="mb-3">
                        <Card>
                            <a href="https://www.surfinityco.ca/" target="_blank" className="text-center">
                                <StaticImage height={150} loading="eager" src="../images/ticker/Surfinity.png" />
                            </a>
                        </Card>
                    </Col>
                    <Col md={3} className="mb-3">
                        <Card>
                            <a href="https://www.swellwake.com/" target="_blank" className="text-center">
                                <StaticImage height={150} loading="eager" src="../images/ticker/Swell.png" />
                            </a>
                        </Card>
                    </Col>
                    <Col md={3} className="mb-3">
                        <Card>
                            <a href="https://store.tige.com/" target="_blank" className="text-center">
                                <StaticImage height={150} loading="eager" src="../images/ticker/Tige.png" />
                            </a>
                        </Card>
                    </Col>
                    <Col md={3} className="mb-3">
                        <Card>
                            <a href="https://wowwatersports.com/" target="_blank" className="text-center">
                                <StaticImage height={150} loading="eager" src="../images/ticker/Wow.png" />
                            </a>
                        </Card>
                    </Col>

                    <Col md={3} className="mb-3">
                        <Card>
                            <a href="https://corkcicle.com/" target="_blank" className="text-center">
                                <StaticImage backgroundColor="#ffffff" height={150} loading="eager" src="../images/ticker/Corkcicleecommerce.png" />
                            </a>
                        </Card>
                    </Col>
                    <Col md={3} className="mb-3">
                        <Card>
                            <a href="https://sisstrevolution.com/" target="_blank" className="text-center">
                                <StaticImage backgroundColor="#ffffff" height={150} loading="eager" src="../images/ticker/Sisstrecommerce.png" />
                            </a>
                        </Card>
                    </Col>

                    <Col md={3} className="mb-3">
                        <Card>
                            <a href="https://www.stingerfoils.com/" target="_blank" className="text-center">
                                <StaticImage backgroundColor="#ffffff" height={150} loading="eager" src="../images/ticker/Stingerecommerce.png" />
                            </a>
                        </Card>
                    </Col>


                    <Col md={3} className="mb-3">
                        <Card>
                            <a href="https://www.sunbum.com/" target="_blank" className="text-center">
                                <StaticImage backgroundColor="#ffffff" height={150} loading="eager" src="../images/ticker/SunBumecommerce.png" />
                            </a>
                        </Card>
                    </Col>


                    <Col md={3} className="mb-3">
                        <Card>
                            <a href="https://www.vissla.com/en-ca" target="_blank" className="text-center">
                                <StaticImage backgroundColor="#ffffff" height={150} loading="eager" src="../images/ticker/Visslaecommerce.png" />
                            </a>
                        </Card>
                    </Col>

                    <Col md={3} className="mb-3">
                        <Card>
                            <a href="https://www.saxxunderwear.ca/" target="_blank" className="text-center">
                                <StaticImage backgroundColor="#ffffff" height={150} loading="eager" src="../images/ticker/Saxxecommerce.png" />
                            </a>
                        </Card>
                    </Col>

                    <Col md={3} className="mb-3">
                        <Card>
                            <a href="https://www.sandcloud.com/" target="_blank" className="text-center">
                                <StaticImage backgroundColor="#ffffff" height={150} loading="eager" src="../images/ticker/SandCloudecommerce.png" />
                            </a>
                        </Card>
                    </Col>

                    <Col md={3} className="mb-3">
                        <Card>
                            <a href="https://rustysurfboards.com/" target="_blank" className="text-center">
                                <StaticImage backgroundColor="#ffffff" height={150} loading="eager" src="../images/ticker/Rustyecommerce.png" />
                            </a>
                        </Card>
                    </Col>

                    <Col md={3} className="mb-3">
                        <Card>
                            <a href="https://www.oakley.com/en-ca" target="_blank" className="text-center">
                                <StaticImage backgroundColor="#ffffff" height={150} loading="eager" src="../images/ticker/Oakleyecommerce.png" />
                            </a>
                        </Card>
                    </Col>

                </Row>
            </Container>
        </Layout>
    )
}
export default CartScreen